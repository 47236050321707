import React, { useContext } from 'react';
import { GetServerSideProps } from 'next';
import AppshellContext from '../../inc/AppshellContext';
import { AuthenticatedLayout, UnauthenticatedLayout, AuthenticatedHHMLayout } from 'components/HomeLayout';
import { useMemberApi } from 'hooks/useMemberApi';
import { MemberInfoOutput } from 'services/api/client';

const Home = (): JSX.Element => {
  const { user } = useContext(AppshellContext);
  const { data } = useMemberApi<MemberInfoOutput>('memberInfo');
  if (data?.household_member) {
    return <AuthenticatedHHMLayout />;
  }
  return user ? <AuthenticatedLayout /> : <UnauthenticatedLayout />;
};

export default Home;

export const getServerSideProps: GetServerSideProps = async () => {
  return {
    props: {},
  };
};
